import { template as template_f6be442c995d4db9a47440ffb8490634 } from "@ember/template-compiler";
const FKText = template_f6be442c995d4db9a47440ffb8490634(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
