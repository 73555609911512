import { template as template_2eaf6aae66fe4075bd39da6eb80c6265 } from "@ember/template-compiler";
const FKLabel = template_2eaf6aae66fe4075bd39da6eb80c6265(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
